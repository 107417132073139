<template>
  <div class="bg-white">
    <div class="mx-auto max-w-7xl py-16 px-4 sm:px-6 lg:py-20 lg:px-8">
      <div class="lg:grid lg:grid-cols-3 lg:gap-8">
        <div>
          <h2 class="text-3xl font-bold tracking-tight text-gray-900">Can't find what you're looking for?</h2>
          <p class="mt-4 text-lg text-gray-500">Reach out to our <a href="mailto:support@complaia.com" class="font-medium text-indigo-600 hover:text-indigo-500">customer support</a> team.</p>
        </div>
        <div class="mt-12 lg:col-span-2 lg:mt-0">
          <dl class="space-y-12">
            <div>
              <dd class="mt-2 text-base text-gray-500">
                If you require any more information or have any questions about our site's disclaimer, please feel free to contact us by email at hello@complaia.com.
              </dd>
              <dd class="mt-2 text-base text-gray-500">
                All the information on this website - https://complaia.com - is published in good faith and for general information purposes only. COMPLAIA SYSTEMS SP. Z O.O. does not make any warranties about the completeness, reliability and accuracy of this information. Any action you take upon the information you find on this website, is strictly at your own risk. COMPLAIA SYSTEMS SP. Z O.O. will not be liable for any losses and/or damages in connection with the use of our website.
              </dd>
              <dd class="mt-2 text-base text-gray-500">
                From our website, you can visit other websites by following hyperlinks to such external sites. While we strive to provide only quality links to useful and ethical websites, we have no control over the content and nature of these sites. These links to other websites do not imply a recommendation for all the content found on these sites. Site owners and content may change without notice and may occur before we have the opportunity to remove a link which may have gone 'bad'.
              </dd>
              <dd class="mt-2 text-base text-gray-500">
                Please be also aware that when you leave our website, other sites may have different privacy policies and terms which are beyond our control. Please be sure to check the Privacy Policies of these sites as well as their "Terms of Service" before engaging in any business or uploading any information.
              </dd>
            </div>
            <div>
              <dt class="text-lg font-medium leading-6 text-gray-900">
                CONSENT
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                By using our website, you hereby consent to our disclaimer and agree to its terms.
              </dd>
            </div>
            <div>
              <dt class="text-lg font-medium leading-6 text-gray-900">
                UPDATE
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                Should we update, amend or make any changes to this document, those changes will be prominently posted here.
              </dd>
            </div>
            <div>
              <dt class="text-lg font-medium leading-6 text-gray-900">
                TESTIMONIALS
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                The testimonials shown on this website related to our course explicitly refer to the individual experiences of individual users, which do not allow conclusions to be drawn about other, future situations and may represent exceptional cases.
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  }
}

</script>
